export const BusinessCategories = [
  {
    "id":"477470",
    "type":"AFIP",
    "name":"Artículos para mascotas",
    "has_child":false
  },
  {
    "id":"454100",
    "type":"AFIP",
    "name":"Herrería y carpintería",
    "has_child":false
  },
  {
    "id":"949930",
    "type":"AFIP",
    "name":"Cooperativa",
    "has_child":false
  },
  {
    "id":"750000",
    "type":"AFIP",
    "name":"Veterinaria",
    "has_child":false
  },
  {
    "id":"342025",
    "type":"AFIP",
    "name":"Imprenta",
    "has_child":false
  },
  {
    "id":"492229",
    "type":"AFIP",
    "name":"Servicio de transporte de mercaderías",
    "has_child":false
  },
  {
    "id":"475290",
    "type":"AFIP",
    "name":"Herramientas y construcción",
    "has_child":false
  },
  {
    "id":"952910",
    "type":"AFIP",
    "name":"Cerrajería",
    "has_child":false
  },
  {
    "id":"492280",
    "type":"AFIP",
    "name":"Flete",
    "has_child":false
  },
  {
    "id":"682091",
    "type":"AFIP",
    "name":"Inmobiliaria",
    "has_child":false
  },
  {
    "id":"523630",
    "type":"AFIP",
    "name":"Ferretería",
    "has_child":false
  },
  {
    "id":"478010",
    "type":"AFIP",
    "name":"Alimentos y bebidas",
    "has_child":false
  },
  {
    "id":"472171",
    "type":"AFIP",
    "name":"Panadería y pastelería",
    "has_child":false
  },
  {
    "id":"471130",
    "type":"AFIP",
    "name":"Autoservicio \u002F Minimercado",
    "has_child":false
  },
  {
    "id":"471120",
    "type":"AFIP",
    "name":"Supermercado",
    "has_child":false
  },
  {
    "id":"452101",
    "type":"AFIP",
    "name":"Lavadero de autos",
    "has_child":false
  },
  {
    "id":"472150",
    "type":"AFIP",
    "name":"Pescadería",
    "has_child":false
  },
  {
    "id":"451290",
    "type":"AFIP",
    "name":"Concesionaria",
    "has_child":false
  },
  {
    "id":"523399",
    "type":"AFIP",
    "name":"Ropa y accesorios",
    "has_child":false
  },
  {
    "id":"466129",
    "type":"AFIP",
    "name":"Lubricentro",
    "has_child":false
  },
  {
    "id":"477130",
    "type":"AFIP",
    "name":"Ropa y accesorios para bebé",
    "has_child":false
  },
  {
    "id":"453291",
    "type":"AFIP",
    "name":"Accesorios para vehículos",
    "has_child":false
  },
  {
    "id":"523330",
    "type":"AFIP",
    "name":"Artículos para bebés",
    "has_child":false
  },
  {
    "id":"464950",
    "type":"AFIP",
    "name":"Pesca y camping",
    "has_child":false
  },
  {
    "id":"141140",
    "type":"AFIP",
    "name":"Confección de prendas deportivas",
    "has_child":false
  },
  {
    "id":"454010",
    "type":"AFIP",
    "name":"Motos",
    "has_child":false
  },
  {
    "id":"475410",
    "type":"AFIP",
    "name":"Mueblería",
    "has_child":false
  },
  {
    "id":"152011",
    "type":"AFIP",
    "name":"Calzado",
    "has_child":false
  },
  {
    "id":"154301",
    "type":"AFIP",
    "name":"Chocolatería",
    "has_child":false
  },
  {
    "id":"476310",
    "type":"AFIP",
    "name":"Bicicletería",
    "has_child":false
  },
  {
    "id":"322001",
    "type":"AFIP",
    "name":"Instrumentos musicales",
    "has_child":false
  },
  {
    "id":"477140",
    "type":"AFIP",
    "name":"Venta al por menor de indumentaria deportiva",
    "has_child":false
  },
  {
    "id":"463180",
    "type":"AFIP",
    "name":"Hipermercado \u002F Mayorista",
    "has_child":false
  },
  {
    "id":"561011",
    "type":"AFIP",
    "name":"Restaurante",
    "has_child":false
  },
  {
    "id":"561013",
    "type":"AFIP",
    "name":"Comida rápida",
    "has_child":false
  },
  {
    "id":"477320",
    "type":"AFIP",
    "name":"Perfumería",
    "has_child":false
  },
  {
    "id":"561030",
    "type":"AFIP",
    "name":"Heladería",
    "has_child":false
  },
  {
    "id":"561020",
    "type":"AFIP",
    "name":"Delivery",
    "has_child":false
  },
  {
    "id":"552114",
    "type":"AFIP",
    "name":"Servicios de bares y confiterias",
    "has_child":false
  },
  {
    "id":"523120",
    "type":"AFIP",
    "name":"Belleza y cuidado personal",
    "has_child":false
  },
  {
    "id":"477440",
    "type":"AFIP",
    "name":"Vivero \u002F Florería",
    "has_child":false
  },
  {
    "id":"479900",
    "type":"AFIP",
    "name":"Máquinas expendedoras",
    "has_child":false
  },
  {
    "id":"523720",
    "type":"AFIP",
    "name":"Joyería y relojería",
    "has_child":false
  },
  {
    "id":"931050",
    "type":"AFIP",
    "name":"Servicios de acondicionamiento físico",
    "has_child":false
  },
  {
    "id":"476120",
    "type":"AFIP",
    "name":"Venta al por menor de diarios y revistas",
    "has_child":false
  },
  {
    "id":"476110",
    "type":"AFIP",
    "name":"Librería",
    "has_child":false
  },
  {
    "id":"551022",
    "type":"AFIP",
    "name":"Hotel",
    "has_child":false
  },
  {
    "id":"960201",
    "type":"AFIP",
    "name":"Peluquería",
    "has_child":false
  },
  {
    "id":"477410",
    "type":"AFIP",
    "name":"Óptica",
    "has_child":false
  },
  {
    "id":"741000",
    "type":"AFIP",
    "name":"Servicio de diseño y publicidad",
    "has_child":false
  },
  {
    "id":"939030",
    "type":"AFIP",
    "name":"Discoteca",
    "has_child":false
  },
  {
    "id":"662020",
    "type":"AFIP",
    "name":"Seguros",
    "has_child":false
  },
  {
    "id":"742000",
    "type":"AFIP",
    "name":"Servicio de fotografía",
    "has_child":false
  },
  {
    "id":"862130",
    "type":"AFIP",
    "name":"Medico",
    "has_child":false
  },
  {
    "id":"474020",
    "type":"AFIP",
    "name":"Telefonía y electrónica",
    "has_child":false
  },
  {
    "id":"231090",
    "type":"AFIP",
    "name":"Fabricación de productos de vidrio n.c.p.",
    "has_child":false
  },
  {
    "id":"869090",
    "type":"AFIP",
    "name":"Servicios de salud",
    "has_child":false
  },
  {
    "id":"329090",
    "type":"AFIP",
    "name":"Arte \u002F Artesanías",
    "has_child":false
  },
  {
    "id":"862200",
    "type":"AFIP",
    "name":"Servicios odontológicos",
    "has_child":false
  },
  {
    "id":"812090",
    "type":"AFIP",
    "name":"Servicios de limpieza",
    "has_child":false
  },
  {
    "id":"939090",
    "type":"AFIP",
    "name":"Teatro",
    "has_child":false
  },
  {
    "id":"475220",
    "type":"AFIP",
    "name":"Carpintería",
    "has_child":false
  },
  {
    "id":"854950",
    "type":"AFIP",
    "name":"Gimnasio",
    "has_child":false
  },
  {
    "id":"949910",
    "type":"AFIP",
    "name":"Servicios de mutuales",
    "has_child":false
  },
  {
    "id":"851010",
    "type":"AFIP",
    "name":"Guarderías y jardines maternales",
    "has_child":false
  },
  {
    "id":"949920",
    "type":"AFIP",
    "name":"Consorcio de edificios",
    "has_child":false
  },
  {
    "id":"854990",
    "type":"AFIP",
    "name":"Establecimiento educativo",
    "has_child":false
  },
  {
    "id":"530090",
    "type":"AFIP",
    "name":"Servicios de mensajerías.",
    "has_child":false
  },
  {
    "id":"311758",
    "type":"AFIP",
    "name":"Fábrica de pastas",
    "has_child":false
  },
  {
    "id":"624179",
    "type":"AFIP",
    "name":"Gomería \u002F Taller mecánico",
    "has_child":false
  },
  {
    "id":"623040",
    "type":"AFIP",
    "name":"Marroquinería, cueros y pieles",
    "has_child":false
  },
  {
    "id":"621021",
    "type":"AFIP",
    "name":"Venta de productos de granja",
    "has_child":false
  },
  {
    "id":"624233",
    "type":"AFIP",
    "name":"Hogar y decoración",
    "has_child":false
  },
  {
    "id":"621102",
    "type":"AFIP",
    "name":"Almacén \u002F Kiosco",
    "has_child":false
  },
  {
    "id":"624284",
    "type":"AFIP",
    "name":"Venta de autopartes",
    "has_child":false
  },
  {
    "id":"624047",
    "type":"AFIP",
    "name":"Cotillón",
    "has_child":false
  },
  {
    "id":"621072",
    "type":"AFIP",
    "name":"Verdulería",
    "has_child":false
  },
  {
    "id":"621013",
    "type":"AFIP",
    "name":"Carnicería",
    "has_child":false
  },
  {
    "id":"711225",
    "type":"AFIP",
    "name":"Transporte de larga distancia",
    "has_child":false
  },
  {
    "id":"631035",
    "type":"AFIP",
    "name":"Bar",
    "has_child":false
  },
  {
    "id":"952028",
    "type":"AFIP",
    "name":"Tintorerías y lavadero",
    "has_child":false
  },
  {
    "id":"949094",
    "type":"AFIP",
    "name":"Balneario",
    "has_child":false
  },
  {
    "id":"959928",
    "type":"AFIP",
    "name":"Funeraria",
    "has_child":false
  },
  {
    "id":"274000",
    "type":"AFIP",
    "name":"Iluminación",
    "has_child":false
  },
  {
    "id":"524120",
    "type":"AFIP",
    "name":"Estacionamiento",
    "has_child":false
  },
  {
    "id":"477310",
    "type":"AFIP",
    "name":"Salud \u002F Farmacia",
    "has_child":false
  },
  {
    "id":"522120",
    "type":"AFIP",
    "name":"Dietética",
    "has_child":false
  },
  {
    "id":"472200",
    "type":"AFIP",
    "name":"Vinoteca",
    "has_child":false
  },
  {
    "id":"823000",
    "type":"AFIP",
    "name":"Organización de eventos",
    "has_child":false
  },
  {
    "id":"473000",
    "type":"AFIP",
    "name":"Combustible",
    "has_child":false
  },
  {
    "id":"952100",
    "type":"AFIP",
    "name":"Servicio de reparación de articulos electrónicos",
    "has_child":false
  },
  {
    "id":"202200",
    "type":"AFIP",
    "name":"Pinturería",
    "has_child":false
  },
  {
    "id":"475300",
    "type":"AFIP",
    "name":"Electrodomésticos",
    "has_child":false
  },
  {
    "id":"861010",
    "type":"AFIP",
    "name":"Centro de salud",
    "has_child":false
  },
  {
    "id":"791100",
    "type":"AFIP",
    "name":"Viajes y turismo",
    "has_child":false
  },
  {
    "id":"591300",
    "type":"AFIP",
    "name":"Cine",
    "has_child":false
  },
  {
    "https://jsonformatter.curiousconcept.com/#id":"841200",
    "type":"AFIP",
    "name":"Servicios para la regulación de las actividades culturales",
    "has_child":false
  },
  {
    "id":"476400",
    "type":"AFIP",
    "name":"Juegos y juguetes",
    "has_child":false
  },
  {
    "id":"949200",
    "type":"AFIP",
    "name":"Organización sin fines de lucro (politicas)",
    "has_child":false
  },
  {
    "id":"941200",
    "type":"AFIP",
    "name":"Servicios para el hogar",
    "has_child":false
  },
  {
    "id":"692000",
    "type":"AFIP",
    "name":"Servicios de contabilidad, auditoría y asesoría fiscal",
    "has_child":false
  },
  {
    "id":"949100",
    "type":"AFIP",
    "name":"Organización sin fines de lucro (religiosas)",
    "has_child":false
  },
  {
    "id":"492190",
    "type":"AFIP",
    "name":"Transporte de pasajeros",
    "has_child":false
  },
  {
    "id":"951100",
    "type":"AFIP",
    "name":"Servicio informático",
    "has_child":false
  },
  {
    "id":"813000",
    "type":"AFIP",
    "name":"Jardinería",
    "has_child":false
  },
  {
    "id":"620900",
    "type":"AFIP",
    "name":"Informática",
    "has_child":false
  },
  {
    "id":"749009",
    "type":"AFIP",
    "name":"Actividades profesionales, científicas y técnicas n.c.p.",
    "has_child":false
  }
].sort((a, b) => {
  if ( a.name < b.name ){
    return -1;
  }
  if ( a.name > b.name ){
    return 1;
  }
  return 0;
})
